<template>
	<div class="page main">
		<QNavbar
			title="Lighthouse"
			sticky
			:nav-items="navBarItems"
			hide-nav-children
			:hide-nav="isMedium || isSmall"
			:hide-toggler="isLarge"
			:hide-offcanvas="isLarge"
		>
			<template #brand-image>
				<SvgImage
					class="logo"
					fill="#e10017"
					url="/images/qubica_logo.svg"
				/>
			</template>
			<template #end>
				<q-navbar-nav>
					<q-nav-item
						:href="repoUrl"
						target="_blank"
					>
						<i class="bi bi-git me-2" />
						<span>v{{ appVersion }}</span>
					</q-nav-item>
					<q-nav-item-dropdown
						text="Color Mode"
						end
					>
						<q-dropdown-item
							v-for="mode in colorModes"
							:key="mode.key"
							:active="activeColorMode === mode.key"
							@click="activeColorMode = mode.key"
						>
							{{ mode.name }}
						</q-dropdown-item>
					</q-nav-item-dropdown>
				</q-navbar-nav>
			</template>
		</QNavbar>
		<main :class="{ 'is-home': isHome }">
			<router-view class="view" />
		</main>
		<footer class="py-0">
			<q-container fluid="md">
				<q-row align-v="center">
					<q-col
						cols="12"
						md="8"
					>
						<div class="d-flex flex-column flex-md-row justify-content-center justify-content-md-start pt-5 pt-md-0">
							<a
								href="/"
								class="pe-md-3 py-md-3 mx-auto mx-md-0"
							>
								<SvgImage
									class="logo"
									fill="#d9d9d9"
									url="/images/qubica_logo.svg"
								/>
							</a>
							<!-- // FIXME: #61674 remove after BNav and BNavbar template implementation -->
							<b-nav class="d-flex-inline flex-column flex-md-row align-items-center">
								<q-nav-item
									variant="secondary"
									href="https://www.qubicaamf.com/"
									target="_blank"
								>
									qubicaamf.com
								</q-nav-item>
							</b-nav>
						</div>
					</q-col>
					<q-col
						cols="12"
						md="4"
					>
						<div class="d-flex justify-content-center justify-content-md-end pb-5 pb-md-0">
							<q-button
								size="sm"
								variant="outline-secondary"
								@click="backToTop()"
							>
								Back to top <i class="bi bi-arrow-right" />
							</q-button>
						</div>
					</q-col>
				</q-row>
			</q-container>
		</footer>
		<QModalOrchestrator />
		<QToastOrchestrator />
	</div>
</template>

<script setup lang="ts">
import { QButton, QCol, QContainer, QDropdownItem, QModalOrchestrator, QNavbar, QNavbarNav, QNavItem, QNavItemDropdown, QRow, QToastOrchestrator, useResponsiveUtilities } from "@qamf/lighthouse/index";
import { useColorMode, UseColorModeOptions } from "@vueuse/core";
import { computed, onMounted, onUnmounted, watch } from "vue";
import { useRoute } from "vue-router";

import SvgImage from "./common/components/svg-image.vue";
import router from "./common/router/router";

const route = useRoute();
const isHome = computed(() => route.name === "home");
const appVersion = __APP_VERSION__;
const repoUrl = "https://dev.azure.com/qamfdev/Frontend/_git/dsy-lighthouse";

const navBarItems = computed(() => {
	if (!route) return [];
	return router.options.routes?.filter(el => el.path !== "/");
});

const backToTop = () => {
	window.scrollTo({
		top: 0,
		left: 0,
		behavior: "smooth"
	});
};

const responsiveUtils = useResponsiveUtilities();

const isSmall = computed(() => { return responsiveUtils.isSmall.value });
const isMedium = computed(() => { return responsiveUtils.isMedium.value });
const isLarge = computed(() => { return responsiveUtils.isLarge.value });

type ColorMode = "light" | "dark" | "laneoptions" | "neoverse";

const useCustomColorMode = (options: UseColorModeOptions<ColorMode>) =>
	useColorMode(options);

const colorModes: { key: ColorMode; name: string }[] = [
	{ key: "light", name: "Light" },
	{ key: "dark", name: "Dark" },
	{ key: "laneoptions", name: "Lane Options" },
	{ key: "neoverse", name: "Neoverse" }
];

const activeColorMode = useCustomColorMode({
	selector: "html",
	attribute: "data-bs-theme",
	storageKey: "vueuse-color-mode",
	initialValue: "light",
	modes: {
		light: "light",
		dark: "dark",
		laneoptions: "laneoptions",
		neoverse: "neoverse"
	}
});

watch(activeColorMode, (newMode) => {
	const html = document.documentElement;
	html.setAttribute("data-bs-theme", newMode);
});

onUnmounted(() => {
	responsiveUtils.destroy();
});

onMounted(() => {
	responsiveUtils.init();
});

</script>
