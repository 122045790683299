import * as AllRules from "@vee-validate/rules";
import { defineRule } from "vee-validate";

const importedRules = ["alpha", "alpha_dash", "alpha_num", "alpha_spaces", "between", "confirmed", "digits", "dimensions", "email", "ext", "image", "integer", "is", "is_not", "length", "max", "max_value", "mimes", "min", "min_value", "not_one_of", "numeric", "one_of", "regex", "required", "size", "toTypedSchema", "url"];

type useRule = {
	defineRule: typeof defineRule
}

export type AvailableRules = {
	[K in keyof typeof importedRules]: K
}[keyof typeof importedRules];

importedRules.forEach(key => {
	defineRule(key, AllRules[key]);
});

export default function useRule(): useRule {
	return {
		defineRule
	};
}
