<template>
	<b-nav
		:vertical="vertical"
		:class="$props.class"
	>
		<b-nav-item
			v-for="item in items"
			:key="item.path"
			:to="navItemTo(item)"
			:disabled="navItemDisabled(item)"
			@click="navItemClicked(item)"
		>
			<span v-if="hideNavItem(item)">{{ navItemLabel(item) }}</span>
			<div v-else-if="item.children">
				<div v-if="dropdownChildren">
					<b-nav-item-dropdown
						:text="navItemLabel(item)"
						right
					>
						<b-dropdown-item
							v-for="(child, index) in item.children.filter(ch => ch.name !== item.name)"
							:key="index"
							:to="child.path"
						>
							{{ child.meta?.title ?? "" }}
						</b-dropdown-item>
					</b-nav-item-dropdown>
				</div>
				<div v-else>
					<b-nav-item
						v-for="(child, index) in item.children"
						:key="index"
						:to="child.path"
					>
						<span :class="{'px-4': child.name !== item.name}">{{ navItemLabel(child) }}</span>
					</b-nav-item>
				</div>
			</div>
		</b-nav-item>
	</b-nav>
</template>

<script lang="ts" src="./q-nav.ts"></script>
