import { createDsy } from "@qamf/lighthouse/index";
import hljs from "highlight.js/lib/core";
import ts from "highlight.js/lib/languages/typescript";
import xml from "highlight.js/lib/languages/xml";
import { createApp } from "vue";

import MainApp from "./app.vue";
import router from "./common/router/router";
hljs.registerLanguage("xml", xml);
hljs.registerLanguage("ts", ts);

const DsyApp = createApp(MainApp);

window.addEventListener("load", () => {
	DsyApp.use(router);
	DsyApp.use(createDsy({
		modalController: true,
		breadcrumb: true,
		modalManager: true,
		toast: true
	}));
	DsyApp.mount("#app");
});
export default DsyApp;
