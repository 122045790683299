import { BNavbar, BNavbarBrand, BOffcanvas, type Breakpoint } from "bootstrap-vue-next";
import { computed, defineComponent, PropType, ref, watch } from "vue";
import { type RouteRecordRaw } from "vue-router";

import QNav from "./../q-nav/q-nav.vue";

export default defineComponent({
	name: "QNavbar",
	components: {
		QNav,
		BNavbar,
		BNavbarBrand,
		BOffcanvas
	},
	props: {
		title: { type: String, default: "" },
		brandTo: { type: [String, Object] as PropType<RouteRecordRaw | string>, default: "/" },
		navItems: { type: Array<RouteRecordRaw>, default: [] },
		hideNav: { type: Boolean, default: false },
		hideNavChildren: { type: Boolean, default: false },
		hideToggler: { type: Boolean, default: true },
		hideOffcanvas: { type: Boolean, default: false },
		togglerIcon: { type: String, default: "bi-list" },
		class: { type: String, default: null },
		container: { type: String as PropType<Breakpoint | "fluid">, default: "xl" },
		disabled: { type: Boolean, default: false },
		sticky: { type: Boolean, default: false },
		offcanvasNavClass: { type: String, default: "" },
		closeOffcanvasOnNavClick: { type: Boolean, default: true }
	},
	setup(props, { attrs }) {
		const instanceAttrs = attrs;
		const offCanvasModel = ref(false);
		const navClasses = computed(() => {
			return props.hideNav ? "d-none" : "d-flex";
		});
		const togglerClasses = computed(() => {
			return props.hideToggler ? "d-none" : "d-block";
		});
		watch(
			() => props.hideOffcanvas,
			(hidden) => {
				if (offCanvasModel.value && hidden)
					offCanvasModel.value = false;
			}
		);
		const toggleOffcanvas = () => {
			if (!props.closeOffcanvasOnNavClick) return;

			offCanvasModel.value = !offCanvasModel.value;
		};

		return {
			instanceAttrs,
			offCanvasModel,
			toggleOffcanvas,
			togglerClasses,
			navClasses
		};
	}
});
