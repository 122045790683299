import { computed, ref } from "vue";

function getCssVariable(property: string) {
	const valueString = getComputedStyle(
		document.documentElement
	).getPropertyValue(property);
	const valueNumber = Number(valueString.split("px")[0]);
	return valueNumber;
}

export function useBreakpoints() {
	return {
		xs: getCssVariable("--bs-breakpoint-xs"),
		sm: getCssVariable("--bs-breakpoint-sm"),
		md: getCssVariable("--bs-breakpoint-md"),
		lg: getCssVariable("--bs-breakpoint-lg"),
		xl: getCssVariable("--bs-breakpoint-xl"),
		xxl: getCssVariable("--bs-breakpoint-xxl")
	};
}

export function useResponsiveUtilities() {
	const winWidth = ref(0);
	const unsubscribe = ref();

	const isExtraSmall = computed(() => {
		return winWidth.value < useBreakpoints().sm;
	});

	const isSmall = computed(() => {
		return winWidth.value < useBreakpoints().md;
	});

	const isMedium = computed(() => {
		return winWidth.value >= useBreakpoints().md && winWidth.value < useBreakpoints().lg;
	});

	const isLarge = computed(() => {
		return winWidth.value >= useBreakpoints().lg;
	});

	const isExtraLarge = computed(() => {
		return winWidth.value >= useBreakpoints().xl;
	});

	const onResize = () => {
		winWidth.value = window.innerWidth;
	};

	const destroy = () => {
		unsubscribe.value();
	};

	const init = () => {
		unsubscribe.value = () => window.removeEventListener("resize", onResize, true);
		onResize();
		window.addEventListener("resize", (event) => onResize());
	};

	const responsiveClasses = computed(() => {
		return {
			"is-small": isSmall.value,
			"is-medium": isMedium.value,
			"is-large": isLarge.value
		};
	});

	return {
		isExtraSmall,
		isSmall,
		isMedium,
		isLarge,
		isExtraLarge,
		destroy,
		init,
		responsiveClasses
	};
}
