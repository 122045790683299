<template>
	<span
		class="svg-wrapper"
		:variant="variant"
		:class="{
			'rounded-icon': rounded,
			'original-color': keepOriginalColors
		}"
	>
		<svg
			v-if="content"
			v-bind="attributes"
			ref="svgElement"
			:fill="fill"
			v-html="content"
		/>
		<slot
			v-else
			name="empty"
		/>
	</span>
</template>

<script setup lang="ts">
import { onMounted, ref } from "vue";

interface ISvg {
	attributes: object;
	content: string;
}
const props = defineProps({
	url: {
		type: String,
		required: true,
		default: ""
	},
	fill: { type: String, default: null },
	excludeDimensions: { type: Boolean, default: true },
	rounded: { type: Boolean, default: false },
	keepOriginalColors: { type: Boolean, default: false },
	variant: { type: String, default: null }
});

const loading = ref(false);
const attributes = ref<object>({});
const content = ref("");
const loadSvg = async(url: string): Promise<ISvg> => {
	const request = await fetch(url, {
		credentials: "omit",
		referrer: location.href,
		method: "GET",
		mode: "cors",
		cache: "force-cache"
	});
	const data = await request.text();
	const div = document.createElement("div");
	div.innerHTML = data;
	const svgElement = div.querySelector("svg");
	if (!svgElement) return { attributes: {}, content: "" };
	const svgAttrs: Record<string, string> = {};
	for (let index = 0; index < svgElement.attributes.length; index++) {
		const attr = svgElement.attributes[index];
		if (props.excludeDimensions && ["width", "height"].includes(attr.name))
			continue;
		svgAttrs[attr.name] = attr.value;
	}
	return { attributes: svgAttrs, content: svgElement.innerHTML };
};
onMounted(async() => {
	if (!props.url) return;
	loading.value = true;
	const { attributes: svgAttrs, content: paths } = await loadSvg(props.url);
	attributes.value = svgAttrs;
	content.value = paths;
	loading.value = false;
});
</script>
<style lang="scss">
	.svg-wrapper {
		display: inline-block;
		max-height: 100%;
		max-width: 100%;
		> svg {
			width: auto;
			height: auto;
			max-height: 100%;
			max-width: 100%;
		}
		&.rounded-icon {
			border-radius: 50%;
			padding: 1.875rem;

			svg path {
				fill: white;
			}
		}
	}
</style>
