<template>
	<b-navbar
		tag="header"
		class="q-navbar"
		:class="$props.class"
		:container="container"
		:sticky="sticky ? 'top' : undefined"
	>
		<div class="slot-start d-flex justify-content-between align-items-center">
			<b-button
				v-if="!hideToggler"
				class="p-0 me-2"
				:class="togglerClasses"
				variant="link"
				@click="toggleOffcanvas"
			>
				<i
					class="h4 bi"
					:class="togglerIcon"
				/>
			</b-button>
			<b-navbar-brand
				class="d-flex"
				:to="brandTo"
			>
				<div
					v-if="$slots['brand-image']"
					class="me-2"
				>
					<slot name="brand-image" />
				</div>
				<slot name="brand-title">
					<span class="d-inline">{{ title }}</span>
				</slot>
			</b-navbar-brand>
			<b-offcanvas
				v-model="offCanvasModel"
				:title="title"
			>
				<slot name="offcanvas">
					<QNav
						class="align-items-start"
						:class="offcanvasNavClass"
						:items="navItems"
						vertical
						@click-item="toggleOffcanvas"
					/>
				</slot>
			</b-offcanvas>

			<slot name="start">
				<QNav
					class="align-items-center"
					:class="navClasses"
					:items="navItems"
					:hide-children="hideNavChildren"
					dropdown-children
				/>
			</slot>
		</div>
		<div
			v-if="$slots.end"
			class="slot-end"
		>
			<slot name="end" />
		</div>
	</b-navbar>
</template>

<script lang="ts" src="./q-navbar.ts"></script>
<style lang="scss" src="./q-navbar.scss"></style>
