import { BNav, BNavItem, BNavItemDropdown } from "bootstrap-vue-next";
import { computed, defineComponent } from "vue";
import { type RouteRecordRaw } from "vue-router";

export default defineComponent({
	name: "QNav",
	components: {
		BNav,
		BNavItem,
		BNavItemDropdown
	},
	props: {
		items: { type: Array<RouteRecordRaw>, required: true },
		hideChildren: { type: Boolean, default: false },
		dropdownChildren: { type: Boolean, default: false },
		class: { type: String, default: "align-items-center" },
		disabled: { type: Boolean, default: false },
		vertical: { type: Boolean, default: false }
	},
	emits: ["click-item"],
	setup(props, { attrs, emit }) {
		const instanceAttrs = attrs;
		const classProp = computed(() => props.class);

		const navItemTo = (item: RouteRecordRaw) => {
			return (props.hideChildren || !item.children) ? item.path : undefined;
		};
		const navItemDisabled = (item: RouteRecordRaw) => {
			return item.meta?.disabled ? Boolean(item.meta?.disabled) : false;
		};
		const navItemLabel = (item: RouteRecordRaw) => {
			return String(item.meta?.title ?? item.name ?? item.path);
		};
		const navItemClicked = (item: RouteRecordRaw) => {
			emit("click-item", item);
		};
		const hideNavItem = (item: RouteRecordRaw) => {
			return props.hideChildren || !item.children;
		};
		return {
			instanceAttrs,
			classProp,
			navItemTo,
			navItemDisabled,
			navItemLabel,
			navItemClicked,
			hideNavItem
		};
	}
});
